import { Button, Stack } from "@mui/material";
import Dialog from "./Dialog";

export const TestSoundDialog = ({show, onSoundTested, soundRef}: {
    show: boolean, 
    onSoundTested?: () => void,
    soundRef: React.RefObject<HTMLAudioElement>
}) => {
    return <Dialog show={show} title={"Test Sound Dialog"}>
        <Stack m={4}>
            <Button variant="contained" onClick={() => {
                if (soundRef.current) {
                    soundRef.current.volume = 0.01;
                    soundRef.current.play();
                }
                onSoundTested?.();
            }}>Press This To Test Sound</Button>
        </Stack>
    </Dialog>
};

export default TestSoundDialog;