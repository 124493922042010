import AngelSoftIcon from "./angel_soft.png";
import CottonStreakerIcon from "./cotton_streaker.png";
import ClaireTharoomIcon from "./claire_tharoom.png";
import CrustyBritchesIcon from "./crusty_britches.png";
import DrStuSamplesIcon from "./dr_stu_samples.png";
import GreasyBrownstoneIcon from "./greasy_brownstone.jpg";
import HardyWiperIcon from "./hardy_wiper.png";
import HoldenToolongIcon from "./holden_toolong.png";
import LincolnLoggsIcon from "./lincoln_loggs.png";
import LoudenLeakyIcon from "./louden_leaky.png";
import LytaMatchIcon from "./lyta_match.png";
import MajorRipperIcon from "./major_ripper.png";
import MarcyFlushIcon from "./marcy_flush.png";
import MilesOStoolIcon from "./miles_o_stool.png";
import PhilMadrarsIcon from "./phil_madrars.png";
import StanKeybottomIcon from "./stan_keybottom.png";
import TrouserPileIcon from "./trouser_pile.png";
import { ICharacter } from '../core/Models';

export const Icons: Map<typeof Characters[number]['_id'], string> = new Map([
	["angel_soft", AngelSoftIcon],
	["cotton_streaker", CottonStreakerIcon],
	["claire_tharoom", ClaireTharoomIcon],
	["crusty_britches", CrustyBritchesIcon],
	["dr_stu_samples", DrStuSamplesIcon],
	["greasy_brownstone", GreasyBrownstoneIcon],
	["hardy_wiper", HardyWiperIcon],
	["holden", HoldenToolongIcon],
	["lincoln_loggs", LincolnLoggsIcon],
	["louden_leaky", LoudenLeakyIcon],
	["lyta_match", LytaMatchIcon],
	["major_ripper", MajorRipperIcon],
	["marcy_flush", MarcyFlushIcon],
	["miles_o_stool", MilesOStoolIcon],
	["phil_madrars", PhilMadrarsIcon],
	["stan_keybottom", StanKeybottomIcon],
	["trouser_pile", TrouserPileIcon]
]);

export const EIcon = (id: string) => import(id);

const AngelSoftCharacter: ICharacter = { _id: "angel_soft", name: "Angel Soft", title: "Shuttle Pilot", description: "Shuttle Pilot Angel Soft is as calm in battle as she is piloting her escape pod from the unstable wreckage of another recently destroyed space craft to which she had been assigned. Years of experience flying through the volatile debris field between Mars and Jupiter have made Angel highly attuned to even the most acute changes to any situation and made her reflexes quicker than lightning. With her ability to adapt to everchanging battle conditions, Soft has developed an uncanny ability to survive while so many around her fall. Is her ability to cheat death truly a product of well-honed combat skills or could she be the fabled Fartian Angel of Death, an alien infiltrator secretly conducting a silent but deadly program to exterminate Earth Command from within the ranks of the aviation corps?" }
const ClaireTharoom: ICharacter = { _id: "claire_tharoom", name: "Claire Tharoom", title: "Recon Specialist", description: "Going in alone is not a problem for Recon Specialist Claire Tharoom. When others retreat, Claire stands her ground. When others can't stand the heat, Claire is cool as a cucumber. A situation may stink to high heavens, but that won't stop Claire. She's not fleeing at the first sign of trouble. She's not leaving until the dust settles and air is cleared. Need to locate a hostile force? She's all over it. Need to identify the source of an unidentified transmission? Claire Tharoom can handle it. Need to survey the environment for toxins? She's probably already in there taking charge. In a word, ownership. That's what Claire Tharoom brings to the party even if that means she's the last one to leave." }
const CottonStreakerCharacter: ICharacter = { _id: "cotton_streaker", name: "Cotton Streaker", title: "Captain", description: "Captain Cotton Streaker has seen more action than any other ship captain in Earth Command. When it comes to face-to-face, frontline engagement of the Fartian enemy, Captain Streaker has truly, as the expression goes, been “in the S#%T”. Despite this extensive battlefield experience, it is rumored that Streaker has never himself been hit by enemy fire. Unfortunately, the same cannot be said for those who've served under his command. Captain Streaker exhibits a questionable propensity for prematurely sacrificing crewmembers without sufficient justification in order to “prevent further contamination at all costs.” The task for the crew of this mission is to decide whether Captain Streaker is the only member of Earth Command who really knows what takes to defeat the enemy. Or is he a well-placed Fartian spy single-handledly responsible for more Earth Command casualties than any other combatant in the war?" }
const CrustyBritches: ICharacter = { _id: "crusty_britches", name: "Crusty Britches", title: "Fuel Specialist", description: "Fuel Specialist Crusty Britches is a quiet sort. He doesn't say much, not even to those who've been around him the longest. It may rub you the wrong way at first, but after a while you grow accustomed to it. You may even come to like it. And, it may go unspoken, but Britches' work is core to the USS Breakwind's success. It's a big solar system, and a ship can only hold so much fuel in its tanks. You have to be selective and intentional about how you expend your gas supplies. Improperly measured petro burns waste precious resources and risk lives. Failure to plan ahead can prevent you from reaching port when you really need to get there. Just as important is mixture ratios. If you get the chemistry wrong, hull pressure may be compromised, resulting in a reactor breach, a premature missile launch, or even an onboard detonation killing indiscriminately without warning. With all that's at stake, be grateful that Crusty is more concerned about checking the fuel lines and gauging the reserve levels than he is about smiling at you he passes in the corridor." }
const DrStuSamples: ICharacter = { _id: "dr_stu_samples", name: "Dr. Stu Samples", title: "Medical Officer", description: "Perhaps no one is more qualified to serve as the USS Breakwind's Medical Officer than Dr. Stu Samples. Samples floated to the top of his class as Earth Command Medical Academy, exiting the program Summa Cum Laude. Ever eager to share the latest, publicly-released material on Fartian emissions, the doctor is, in the opinions of some, a man of inflated ego frequently radiating accolades of his scientific insights. To others, Stu Samples is an altruist intent on demystifying the unknown, believing science is the best defense against the stench of ignorance. For this decorated Medical Officer, the main intellectual challenge is in overcoming the reticence of others to absorb all he has to share. His utter devotion to the study of gross anatomy in search of a complete theory of xeno-flatulence may be admirable, but distrust of Samples is brewing due largely to recent whispers that the doc has taken to intentionally exposing himself and others lab-based recreations of Fartian gas bombs or, worse yet, the genuine article." }
const GreasyBrownstone: ICharacter = { _id: "greasy_brownstone", name: "Greasy Brownstone", title: "Ships Mechanic", description: "Ships Mechanic Greasy Brownstone may be a brown-thumb when it comes to biology, but he is an absolute magician when it comes to spacecraft maintenance. There's no finer a technician in the fleet than this diamond in the rough. Beneath the stained and sullied exterior, is a man beloved by his fellow crewmembers. Down to earth and easy-going, Brownstone is not afraid to get his hands dirty when a friend or teammate is in a pickle. Greasy, as his friends call him, is just as happy sweating it out, white-knuckled and hunched over a leaky gasket in the bowels of the ship as he is having a drink with his shipmates in the galley after a long shift. The regular joke among the crew is why a ship with a mechanic as competent as Brownstone is constantly in need of repairs. It's a good question." }
const HardyWiper: ICharacter = { _id: "hardy_wiper", name: "Hardy Wiper", title: "Robotics Officer", description: "The USS Breakwinds' Robotics Operator has had a rough time of it. His course has definitely not been a smooth one. Ya, nothing comes easy when you're Hardy Wiper. In spite of it all, Hardy just bears down and takes it. It'll take more than a few bruises and scratches to shake this stubborn grunt. He's got his share of bloodstains on his clothes for sure. Most of it is his. But he's not deterred. He's seen worse. He's been around for a while. And he'll be here for a while longer. He's in it for the long haul. He will hang on after all the others are gone. When you've had a long, tough go of it and its finally time to finish the job, Hardy Wiper is there with a firm, unrelenting, forceful hand." }
const HoldenToolong: ICharacter = { _id: "holden", name: "Holden Toolong", title: "Intelligence Officer", description: "Intelligence Officer Holden Toolong has a secret. In fact, he has many secrets. He's been sitting on these secrets for a long time. If he let one out, it might kill you. It might kill all of us. Holden is a loner, capable of keeping his distance when needed or beguiling unsuspecting assets into taking the fall for him. He exploits weaknesses and harnesses the strengths of others to his advantage. Such is the life of a high-ranking, career officer from the Intelligence Bureau Security forces, Earth Command's most clandestine and feared agency. Make no mistake, IBS is real. It can be extremely disruptive and it can strike at any time, during a meal, at the end of a meal, an hour after the meal, or when you reluctantly let your friends talk you into a double scoop of real diary ice cream after an otherwise uneventful dinner. Then you spend the rest of your evening playing some new device-based, in-home, group game hoping that the rumbly intestinal distress you are experiencing is just gas and that the only thing you let out is a few soft ones. It's a long game. Fingers crossed. IBS is a serious is serious condition. If you or someone you know is experiencing intestinal distress, speak with your physician." }
const LincolnLoggs: ICharacter = { _id: "lincoln_loggs", name: "Lincoln Loggs", title: "Weapons Officer", description: "Weapons Officer Lincoln Loggs is more than comfortable in any hot zone. He can name the serial number of every weapon and round commissioned by Earth Command for the better part of a century. Fastidious and prepared for any scenario, Lincoln has secretly deposited ordnance throughout the entire ship, particularly in the dark places, where the sun never shines and where no one would dare search. Around every corner are Loggs' souvenirs. You can't see them, but you know they are there. Their presence is undeniable. Besides Lincoln's covert, shipwide stockpiles, Lincoln has no shortage of ammo that he is keeping warm on his person, but ready to go. As a result, Loggs is always a threat. A true marksman with a high kill score and an unsmeared and unbesmirched record. He is equally effective when laying down a sustained line of suppressive fire or a single, calculated burst." }
const LoudenLeaky: ICharacter = { _id: "louden_leaky", name: "Louden Leaky", title: "Navigation Officer", description: "Louden Leaky may not be a captain's first choice for a Navigation Officer. He can be unpleasant. He's overbearing. He has a big mouth and he can't seem keep it shut. His near total lack of decorum or concern for social niceties can make people wonder if he's from another planet. Sure, Louden Leaky isn't great when your socializing at a party or invited to a formal Earth Command function. But, when it comes to reading astronomical charts and plotting courses, the name of the game is location, location, location. And that's where Officer Leaky is preferrable by Captain Cotton Streaker to his more polite and softspoken colleagues at Earth Command's Navigation Division. You always know where he is coming from and where you stand with him. So, while he lacks discretion, Louden more than makes up for in terms of confidence. He owns his work. There's no denying it. He knows it. The whole crew knows it." }
const LytaMatch: ICharacter = { _id: "lyta_match", name: "Lyta Match", title: "Science Officer", description: "Science Officer Lyta Match has always been a self-starter, an adventurer with a mind for discovery. She's an optimist, eager to make a mark on history, ready to make a difference, driven by the exhilaration of being the first to go where none of her people have gone before her, to bring the spark of enlightenment to the darkness of outer space. She dares to step out into the unknown, to venture far from home, to discover and master new worlds, learn about alien species, and advance the cause of science throughout the solar system. Few who serve with Lyta will be left untouched by her work. Though they may never know her by name, few will fail to notice the strength of her contributions even long after her candle has gone out." }
const MajorRipper: ICharacter = { _id: "major_ripper", name: "Major Ripper", title: "Chief Security Officer", description: "Chief Security Officer Major Ripper is a hardened and ready-to-go combatant. His resolve has been tested time and again. Never was there a member of Earth Command who was more eager to join (or start) the fight. His fellow crewmen will tell you he has an itchy trigger finger and a short fuse. Tales of his quick temper echo through Earth Command. Ripper is a pressure cooker always simmering below the surface and ready to burst at the seams. Still, there's no one you'd rather have watching your six in a pinch than the Major. He continues to hold when lesser men have long since given in to the pressure. Locked and loaded at all times, Earth Command's sternest Security Chief is ready to release hell the second he sees nostrils flare. You better know whose side your on, because when the s hits the fan and the smoke clears, you can bet it started and ended by a classic Major Ripper blowup." }
const MarcyFlush: ICharacter = { _id: "marcy_flush", name: "Marcy Flush", title: "Cadet", description: "Cadet Marcy Flush is no shrinking violet. The youngest of nine, Marcy is the latest addition to a long line Flushes at Earth Command. “In a big family like mine,” Marcy explains, “you have to wait in line. And even though you may be last to go, you can't wilt when it's your turn. You have to fight for the air you breathe.” Now, Flush takes a backseat to no one. It's a tradition started by her great-grandfather the distinguished Commodore Royal Flush, first to land on the dark side of the moon. “He was a real straight shooter,” Cadet Flush recalls. “I take after him a lot.” Despite being from a legacy family and graduating from the academy in just three short years instead of the usual four, Marcy Flush's plans haven't exactly gone off without a hitch. Her older brother, Rowdy Flush, was charged as a Fartian sympathizer and dishonorably discharged from his post. Concerns abounded over the dependability of Marcy Flush as well. Her deployment was rescheduled twice as the fowl situation continued to stagnate. Finally, the orders came through. Both flushes were cleared by Earth Command and Marcy took her place aboard the USS Breakwind. “I'm tired of everyone questioning my loyalties,” Marcy says, “I just want to get out there and make a difference!”" }
const MilesOStool: ICharacter = { _id: "miles_o_stool", name: "Miles O' Stool", title: "Chief Systems Engineer", description: "Miles O'Stool has earned his stripes. You don't get to where he's at in Earth Command without being willing to take one or two for the team. That's how you earn a spot as the Chief Systems Engineer on one of the agency's flagships under the command of a renowned captain like Cotton Streaker. Miles has learned the hard way, if you want to survive in the dark of outer space, you need to be precise in your calculations. Your instrumentation and systems must function optimally at all times. That means you're going to have to force your way through some tight situations to keep your ship together. Everything has to work toward the same outcome. There's no room for error. Space is cold. Space is empty. That's where Miles O'Stool comes in, filling the gaps, sealing the cracks. It's his job to make sure the USS Breakwind is spaceworthy and all systems go. For a chief systems engineer like O'Stool, flying by the seat of your pants just isn't an option. When everything is running optimal, you know you're aboard a craft with Miles O'Stool." }
const PhilMadrars: ICharacter = { _id: "phil_madrars", name: "Phil Madrars", title: "Payload Specialist", description: "Payload Specialist Phil Madrars here to tell cadets why they should enlist in Earth Command's Payload Specialist Training Program. My crewmates always ask me, “Hey Phil, what do you even do on the ship?” or “Hey Phil, why do we even need a Payload Specialist?” And I always tell them the same thing. The next time you have a stockpile building up and you are desperately searching for somewhere to drop it, just take a deep breath and tell yourself: “I know what to do when I really need a place to take my crap, Phil Madrars!” And you know what? Once they've had that experience, they never ask me again. A Payload Specialist is trained to handle all sorts of cargo and supplies that Earth Command vessels need to carry out their missions in space. We also play an important role in making sure hazardous waste products are safely and securely stored away so they don't become a problem for the entire crew. Plus, it's way easier and a heck of a lot more convenient than the alternatives. I mean what's the other option, push your crap out an airlock like they used to do in the early days?! Not only does that make it everybody's problem, but it's a good way to get your butt sucked into outer space! In any case, its frowned upon and Earth Command is really cracking down on that sort of thing nowadays. So, why fight it? Just go with the flow. Join Earth Command's Payload Specialist Training Program." }
const StanKeyBottom: ICharacter = { _id: "stan_keybottom", name: "Stan KeyBottom", title: "Helmsman", description: "Helmsman Stan KeyBottom is about as good as it gets, and he'll be the first to let you know. He's high on his own product and that product is self-esteem. He's fuming with pride, full of himself. But it may have been that self-propelled self-assurance that led him to achieve second spot in the Spaceflight Core's elite training program earning him the nickname Deuces. So, while he could be the most arrogant aviator in the fleet, KeyBottom certainly has the track record to back it up. Capable of steering any vessel, large or small, safely into port at Full Moon Station, Stan also singlehandedly holds the title for the longest, uninterrupted session in the chair. Full throttle and all at once, KeyBottom's go-to style, produced a rip-roaring, cabin-shaking, speed ride from Uranus to splashdown in under 18 hours. He can handle the rush even if his fellow crewmembers can't always handle the atmosphere that comes with it. With his undeniable talents, the only real question is whether Helmsman Stan KeyBottom is really that self-assured or is his pronounced confidence a cover, meant to distract from some deeper, hidden flaw or secret." }
const TrouserPile: ICharacter = { _id: "trouser_pile", name: "Trouser Pile", title: "Cook", description: "Cook Trouser Pile isn't much to look at. But you should've seen him in his prime. In his day, Trouser had a promising Earth Command career set before him. He was commissioned to lead the first and only attempted invasion of Poopiter. The plan was to take the alien world by storm and overwhelm, what appeared would be a meager resistance, with sheer volume. That's where Trouser Pile came in. His unit was the largest to land in a single strike. But it was all a miscalculation. Not long after touchdown, Trouser Pile found himself buried in an avalanche of debris. It all happened so fast. At one point, you couldn't even tell where it was all coming from. Above? Below? From the side? Explosions were everywhere. Then everything went black. It was a Fartian trap mistaken as a single, unthreatening discharge. What it really was, was much worse – a minefield, lying in wait, for someone to step in. It was claimed that an alien mole must have infiltrated Earth Command. But a perpetrator was never identified. Trouser Pile was injured in the attack. But, after being patched up he found a way to continue the fight as a ship's cook aboard a vessel captained by an old bootcamp bunkmate named Cotton Streaker." }

export const Characters = [
	CottonStreakerCharacter,
	AngelSoftCharacter,
	MajorRipper,
	DrStuSamples,
	GreasyBrownstone,
	PhilMadrars,
	LoudenLeaky,
	HoldenToolong,
	LincolnLoggs,
	HardyWiper,
	MilesOStool,
	LytaMatch,
	ClaireTharoom,
	CrustyBritches,
	StanKeyBottom,
	TrouserPile,
	MarcyFlush
] as const;
